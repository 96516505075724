import { apiNoHeader } from "./BaseUrl";


// CMS Data  API
export const getCMS = async (type) => {
  let data;
  await apiNoHeader
    .get(`/cms/${type}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Banners Data  API
export const getBanners = async () => {
  let data;
  await apiNoHeader
    .get(`/banners?type=web`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Settings Data  API
export const getSettings = async () => {
  let data;
  await apiNoHeader
    .get(`/settings`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Vendors Data  API
export const getVendors = async () => {
  let data;
  await apiNoHeader
    .get(`/vendors?is_featured=true`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Submit Contact Form
export const postContactUs = async (values) => {
  let data;
  await apiNoHeader
    .post("/content/contact-us", {
      "message": values.message,
      "name": values.full_name,
      "email": values.email,
      "phone": values.phone,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};