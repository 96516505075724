import React from "react";
import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { scrollTop } from "../../Services/Utils/Utils";
import "./FAQs.css";
import { getCMS } from "../../Services/Api/Api";

const FAQs = () => {
  const [activeKey, setActiveKey] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getCMS("faqs");
    const { data, status, error } = result;
    if (status === 200) {
      setData(data?.cms);
    } else {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   scrollTop();
  // }, []);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  const faqData = [
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      title: "sed do eiusmod tempor incididunt ut labore et dolore?",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      title: "Excepteur sint occaecat cupidatat non proident?",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      title: "Ut enim ad minim veniam?",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      title: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur?",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  return (
    <div id="faqs" className="faqs">
      <div className="container">
        <h2 className="section-title">Frequently Asked Questions</h2>
        <div className="col-lg-8 offset-lg-2">
          <div className="faq-accordians">
            <Accordion activeKey={activeKey} onSelect={handleSelect} >
              {data?.filter(item => item.active)
              .map((item, index) => (
                <Accordion.Item
                  key={index}
                  className={
                    activeKey === index ? "active" : null
                  }
                  eventKey={index}
                >
                  <Accordion.Header>
                    {item?.title}
                    <span
                      className="float-right success-button"
                      variant={activeKey === index ? "danger" : "success"}
                      onClick={() =>
                        handleSelect(activeKey === index ? null : index)
                      }
                    >
                      {activeKey === index ? (<i className="bi bi-dash"></i>) : (<i className="bi bi-plus"></i>)}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
