import React from "react";
import "./PageHeader.css";
import { Link } from "react-router-dom";

const PageHeader = ({ pageTitle }) => {
  return (
    <div className="pageHeader">
      <h2 className="page-title"> {pageTitle} </h2>
      <div className="breadCrumbs">
        <Link to="/">Home</Link> - {pageTitle}
      </div>
    </div>
  );
};

export default PageHeader;