import React, { useEffect } from "react";
import { AboutBanner, Banner2, OurMission, OurStory, PageHeader, } from "../../Components";
import { scrollTop } from "../../Services/Utils/Utils";

const About = () => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div>
      <PageHeader pageTitle="About Us" />
      <AboutBanner />
      <OurStory />
      <OurMission />
      <Banner2 />
    </div>
  );
};

export default About;