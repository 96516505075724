import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { getSettings } from "../../Services/Api/Api";
import { updateContactDetails } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState("");
  const [data, setData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let date = new Date();
    let year = date.getFullYear();
    setCurrentYear(year);

    getData();
  }, []);

  const getData = async () => {
    const storedData = sessionStorage.getItem("footerData");
    if (storedData && data !== undefined) {
      const parsedData = JSON.parse(storedData);
      setData(parsedData?.settings);
      dispatch(updateContactDetails(parsedData));
      setBaseUrl(parsedData?.base_url);
    } else {
      const result = await getSettings();
      const { data, status, error } = result;
      if (status === 200) {
        setData(data?.settings);
        setBaseUrl(data?.base_url);
        sessionStorage.setItem("footerData", JSON.stringify(data));
        dispatch(updateContactDetails(data));
      }
    }
  };

  const { email, address, phone } = data?.contact_information || {};
  const { facebook, instagram, linkedin, twitter, youtube } = data?.social_media_links || {};

  return (
    <footer className="footer">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 brand-info">
              <Link to="/" className="logo">
                <img src={baseUrl + data?.logo} />
              </Link>
              <div className="social-links">
                <a href={facebook} target="_blank">
                  <img src="assets/icons/facebook.png" />
                </a>
                <a href={twitter} target="_blank">
                  <img src="assets/icons/x.png" />
                </a>
                <a href={instagram} target="_blank">
                  <img src="assets/icons/instagram.png" />
                </a>
                <a href={youtube} target="_blank">
                  <img src="assets/icons/youtube.png" />
                </a>
                <a href={linkedin} target="_blank">
                  <img src="assets/icons/linkedin.png" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h3 className="heading">Quick Links</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About</Link>
                </li>
                <li>
                  <HashLink to="/#how-it-works">How It Works</HashLink>
                </li>
                <li>
                  <HashLink to="/#faqs">FAQ's</HashLink>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h3 className="heading">Other Links</h3>
              <ul>
                <li>
                  <a href='https://vendor.thegallery-llc.com/register' target="_blank">
                    Become a Vendor
                  </a>
                  </li>
                <li>
                  <Link to="/terms-condtions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h3 className="heading">Contact Info</h3>
              <ul>
                <li className="address">{address}</li>
                <li className="email">{email}</li>
                <li className="mobile">{phone}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        Copyright © <span>{currentYear}</span>{" "}
        <span className="website">The Gallery - An Artist’s Collection</span>{" "}
        All Rights Reserved. Powered by <a className="website" href="https://www.branex.ae/" target="_blank">Branex.</a> 
      </div>
    </footer>
  );
};

export default Footer;
