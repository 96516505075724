import React, { useEffect } from "react";
import { Banner1, Banner2, FAQs, FeaturedVendors, HowItWorks, MainBanner, } from "../../Components";
import { scrollTop } from "../../Services/Utils/Utils";

const Home = () => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div>
      <MainBanner />
      <FeaturedVendors />
      <Banner1 />
      <HowItWorks />
      <Banner2 />
      <FAQs />
    </div>
  );
};

export default Home;