import React, { useEffect, useState } from "react";
import "./OurMission.css";
import { getCMS } from "../../Services/Api/Api";

const OurMission = () => {
  const [data, setData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getCMS("our-mission");
    const { data, status, error } = result;
    if (status === 200) {
      setData(...data?.cms);
      setBaseUrl(data?.base_url + "uploads/");
    } else {
      console.log(error);
    }
  };

  return (
    <div className="ourMission">
      <div className="text-col">
        <div className="text-wrapper">
          <h2 className="banner-title">{data?.title}</h2>
          <p className="lead">{data?.sub_title}</p>

          {data?.points && (
            <>
              <div className="mission-div">
                <h4 className="heading">
                  <span className="icon">
                    <img src="assets/icons/mission.png" alt="" />
                  </span>
                  <span> {data?.points[0]?.title} </span>
                </h4>
                <p> {data?.points[0]?.description} </p>
              </div>
              <div className="vision-div">
                <h4 className="heading">
                  <span className="icon">
                    <img src="assets/icons/vision.png" alt="" />
                  </span>
                  <span> {data?.points[1]?.title} </span>
                </h4>
                <p>{data?.points[1]?.description}</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="img-col">
        <img src={baseUrl + data?.img} />
      </div>
    </div>
  );
};

export default OurMission;