import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: {
    base_url: "",
    settings: {
      app_links: {
        google_play: "",
        app_store: "",
      },
      contact_information: {
        phone: "",
        email: "",
      },
      social_media_links: {
        facebook: "",
        instagram: "",
        linkedin: "",
        twitter: "",
        youtube: "",
      },
    },
  },
};

export const contactDetailsSlice = createSlice({
  name: "contactDetails",
  initialState,
  reducers: {
    updateContactDetails: (state, action) => {
      return {
        ...state,
        values: action.payload,
      };
    },
  },
});

export const store = configureStore({
  reducer: {
    contactDetails: contactDetailsSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const { updateContactDetails } = contactDetailsSlice.actions;
export const selectContactDetails = (state) => state.contactDetails;
export default store;
