import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import AppRoutes from "./Routes/Routes";
import './App.css'

const App = () => {
  return (
    <AppRoutes/>
  );
};

export default App;
