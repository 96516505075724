import React, { useEffect, useState } from "react";
import { PageHeader } from "../../Components";
import { getCMS, postContactUs } from "../../Services/Api/Api";
import "./Contact.css";
import { Form, Input, notification } from "antd";
import Button from "@mui/material/Button";
import { scrollTop } from "../../Services/Utils/Utils";

const Contact = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      address: "",
      emails: ["", ""],
      phones: ["", ""],
    },
  ]);
  const { address, emails, phones } = data || {};
  const [email_1, email_2] = emails || [];
  const [phone_1, phone_2] = phones || [];

  const getData = async () => {
    const result = await getCMS("contact-us");
    const { data, status, error } = result;
    if (status === 200) {
      setData(...data?.cms);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    scrollTop();
    getData();
  }, []);

  // Submit Contact Us Form Data
  const onFinish = async (values) => {
    const result = await postContactUs(values);
    const { data, status, error } = result || {};
    if (status === 200) {
      form.resetFields();
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: error?.message });
    }
  };

  return (
    <div>
      <PageHeader pageTitle="Contact Us" />
      <div className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-info">
                <h2 className="section-title-2">Contact Information</h2>
                <div className="address">
                  <h4 className="heading">Address</h4>
                  <div>{data?.address}</div>
                </div>
                <div className="email">
                  <h4 className="heading">Email</h4>
                  {data?.emails?.map((email, index) => (
                    <div key={index}>
                      <a href={`mailto:${email}`}> {email}</a>
                    </div>
                  ))}
                </div>
                <div className="mobile">
                  <h4 className="heading">Phone</h4>
                  {phone_1 && <div> 
                    <a href={`tel:${phone_1}`}>
                    Tel: {phone_1}
                    </a>
                    </div>}
                  {phone_2 && <div> 
                    <a href={`tel:${phone_2}`}>
                    Phone: {phone_2}
                    </a>
                    </div>}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form">
                <h2 className="section-title-2">Get In Touch</h2>
                <Form form={form} name="basic" onFinish={onFinish}>
                  <Form.Item
                    name="full_name"
                    rules={[
                      {
                        required: true,
                        message: "You cannot leave this field blank",
                      },
                      {
                        max: 100,
                        message: "Can not be more than 100 characters.",
                      },
                    ]}
                  >
                    <Input placeholder="Full Name" size="large" />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    type="email"
                    rules={[
                      {
                        type: "email",
                        message: "Invalid email",
                      },
                      {
                        required: true,
                        message: "You cannot leave this field blank",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      size="large"
                      type="email"
                    />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "You cannot leave this field blank.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone Number"
                      size="large"
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "You cannot leave this field blank",
                      },
                      { min: 10, message: "Should be 10 characters." },
                      {
                        max: 1000,
                        message: "Can not be more than 1000 characters.",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Your message here..."
                      size="large"
                      rows={5}
                    />
                  </Form.Item>

                  <div className="form-contact-button">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      className="main-button"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
