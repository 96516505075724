import React, { useEffect, useState } from "react";
import { PageHeader } from "../../Components";
import { getCMS } from "../../Services/Api/Api";
import "./PrivacyPolicy.css";
import { scrollTop } from "../../Services/Utils/Utils";

const PrivacyPolicy = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    scrollTop();
    getData();
  }, []);

  const getData = async () => {
    const result = await getCMS("privacy-policy");
    const { data, status, error } = result;
    if (status === 200) {
      setData(data?.cms);
    } else {
      console.log(error);
    }
  };

  return (
    <div>
      <PageHeader pageTitle="Privacy Policy" />
      <div className="privacy-policy">
        <div className="container">
          {data
            ?.filter((item) => item.active)
            .map((item, index) => (
              <div key={index}>
                <h3 className="heading"> {item?.title} </h3>
                <div className="border"></div>
                <div className="desc"> {item?.description} </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;