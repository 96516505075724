import React, { useEffect, useState } from "react";
import "./Banner.css";
import { useSelector } from "react-redux";
import { selectContactDetails } from "../../Store/Store";
import { getCMS } from "../../Services/Api/Api";

const Banner2 = () => {
  const [data, setData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const contactDetails = useSelector(selectContactDetails);
  const { google_play, app_store } =
    contactDetails?.values?.settings?.app_links;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getCMS("bottom-banner");
    const { data, status, error } = result;
    if (status === 200) {
      setData(...data?.cms);
      setBaseUrl(data?.base_url + "uploads/");
    } else {
      console.log(error);
    }
  };

  return (
    <div className="banner banner-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img
              src={baseUrl + data?.img}
              className="banner-img"
              alt="banner"
            />
          </div>
          <div className="col-lg-6">
            <div className="text-col">
              <h2 className="banner-title">{data?.title}</h2>
              <p className="lead">{data?.sub_title}</p>
              <p>{data?.description}</p>
              <div className="button-group">
                <a href={google_play ? google_play : "#"} target="_blank">
                  <img src="assets/playstore.png" alt="playstore" />
                </a>
                <a href={app_store ? app_store : "#"} target="_blank">
                  <img src="assets/appstore.png" alt="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner2;
