import React, { useEffect, useState } from "react";
import "./FeaturedVendors.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, EffectFade, Pagination } from "swiper/modules";
import { getVendors } from "../../Services/Api/Api";

const FeaturedVendors = () => {
  const [data, setData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getVendors();
    const { data, status, error } = result;
    if (status === 200) {
      setData(data?.vendors);
      setBaseUrl(data?.base_url);
    } else {
      console.log(error);
    }
  };

  return (
    <div className="featured-vendors">
      <div className="container">
        <h2 className="section-title"> Featured Vendors </h2>

        <Swiper
          breakpoints={{
            1200: {
              slidesPerView: 5,
            },
            991: {
              slidesPerView: 4,
            },
            767: {
              slidesPerView: 3,
            },
            500: {
              slidesPerView: 2,
            },
          }}
          // effect={"fade"}
          // speed= {1000}
          // autoplay={{
          //   delay: 10000,
          //   disableOnInteraction: false,
          // }}
          navigation={true}
          // pagination={{
          //   dynamicBullets: true,
          // }}

          // slidesPerView={5}
          spaceBetween={45}
          // pagination={{
          //   clickable: true,
          // }}
          modules={[
            //   Autoplay,
            //   EffectFade,
            // Pagination,
            Navigation,
          ]}
          className="featured-list"
        >
          {/* <SwiperSlide>
            <img src="assets/vendors/vendor1.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/vendors/vendor2.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/vendors/vendor3.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/vendors/vendor4.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/vendors/vendor5.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/vendors/vendor5.png" alt="" />
          </SwiperSlide> */}
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={baseUrl + item?.img} alt={item?.title} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default FeaturedVendors;