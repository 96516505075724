import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./MainBanner.css";

import {
  Autoplay,
  Navigation,
  EffectFade,
  // Pagination
} from "swiper/modules";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { getBanners } from "../../Services/Api/Api";

const MainBanner = () => {
  const [data, setData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getBanners();
    const { data, status, error } = result;
    if (status === 200) {
      setData(data?.banners);
      setBaseUrl(data?.base_url);
    } else {
      console.log(error);
    }
  };

  return (
    <>
      <Swiper
        effect={"fade"}
        speed={1000}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        navigation={true}
        // pagination={{
        //   dynamicBullets: true,
        // }}
        modules={[
          Autoplay,
          EffectFade,
          // Pagination,
          Navigation,
        ]}
        className="mainBanner"
      >
        {data
          ?.filter((item) => item.status)
          .map((item, index) => (
            <SwiperSlide key={index}>
              <div className="banner-content-wrapper">
                <div className="row">
                  <div className="col-xl-5 col-lg-6">
                    <div className="banner-content">
                      <h2 className="banner-title">{item?.alt}</h2>
                      <p>{item?.description}</p>
                      <Link to="/contact-us">
                        <Button
                          variant="contained"
                          size="large"
                          className="main-button"
                        >
                          Contact Us
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6">
                    <img src={baseUrl + item.img_web} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default MainBanner;