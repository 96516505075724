import React, { useEffect, useState } from "react";
import "./HowItWorks.css";
import { getCMS } from "../../Services/Api/Api";

const HowItWorks = () => {
  const [vendorData, setVendorData] = useState([]);
  const [quilterData, setQuilterData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    getVendorData();
    getQuilterData();
  }, []);

  const getVendorData = async () => {
    const result = await getCMS("how-it-works");
    const { data, status, error } = result;
    if (status === 200) {
      setVendorData(...data?.cms);
      setBaseUrl(data?.base_url + "uploads/");
    } else {
      console.log(error);
    }
  };

  const getQuilterData = async () => {
    const result = await getCMS("join-as-quilters");
    const { data, status, error } = result;
    if (status === 200) {
      setQuilterData(...data?.cms);
    } else {
      console.log(error);
    }
  };

  return (
    <div id="how-it-works" className="howItWorks">
      <div className="container">
        <h2 className="section-title">How It Works</h2>

        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-quilter-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-quilter"
              type="button"
              role="tab"
              aria-controls="pills-quilter"
              aria-selected="true"
            >
              For Quilter’s
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-vendor-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-vendor"
              type="button"
              role="tab"
              aria-controls="pills-vendor"
              aria-selected="false"
            >
              For Vendor’s
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-quilter"
            role="tabpanel"
            aria-labelledby="pills-quilter-tab"
            tabIndex="0"
          >
            <Points data={quilterData} img={"assets/how-quilter.png"} />
          </div>
          <div
            className="tab-pane fade"
            id="pills-vendor"
            role="tabpanel"
            aria-labelledby="pills-vendor-tab"
            tabIndex="0"
          >
            <Points data={vendorData} img={"assets/how-vendor.png"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;

const Points = ({ data, img }) => {
  const [videoUrl, setVideoUrl] = useState("");
  useEffect(() => {
    if (data?.video_link) {
      const fullUrl = data?.video_link;
      const urlParts = fullUrl.split("/");
      setVideoUrl(urlParts[urlParts.length - 1]);
    }
  }, [data]);

  return (
    <div>
      <p className="col-lg-6 offset-lg-3 text-center lead">
        {data?.description}
      </p>
      <div className="row">
        <div className="col-lg-6">
          <ul className="points">
            {data?.points?.map((item, index) => (
              <li key={index}>
                <h3 className="heading">{item?.title}</h3>
                <p>{item?.description}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-6">
          <div className="media-col">
            <img src={img} alt="" />
          </div>
          {/* <div className="media-col">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${videoUrl}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div> */}
        </div>
      </div>
    </div>
  );
};
