import React, { useEffect, useState } from "react";
import "./OurStory.css";
import { getCMS } from "../../Services/Api/Api";

const OurStory = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getCMS("our-story");
    const { data, status, error } = result;
    if (status === 200) {
      setData(...data?.cms);
    } else {
      console.log(error);
    }
  };

  return (
    <div className="ourStory">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="text-col">
              <h2 className="banner-title">{data?.title}</h2>
              <p className="lead"> {data?.sub_title} </p>
              <p>{data?.description}</p>
            </div>
          </div>
          <div className="col-lg-6">
            {data?.points && (
              <div className="stats">
                <div className="stats-row stats-row-1">
                  <div className="stats-card">
                    <div className="number">{data?.points[0].title}</div>
                    <div className="text"> {data?.points[0].description} </div>
                  </div>

                  <div className="stats-card">
                    <div className="number">{data?.points[1].title}</div>
                    <div className="text"> {data?.points[1].description} </div>
                  </div>
                </div>
                <div className="stats-row stats-row-2">
                  <div className="stats-card">
                    <div className="number">{data?.points[2].title}</div>
                    <div className="text"> {data?.points[2].description} </div>
                  </div>

                  <div className="stats-card">
                    <div className="number">{data?.points[3].title}</div>
                    <div className="text"> {data?.points[3].description} </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;