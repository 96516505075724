import React, { useEffect, useState } from "react";
import "./Banner.css";
import { getCMS } from "../../Services/Api/Api";

const AboutBanner = () => {
  const [data, setData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getCMS("about-us");
    const { data, status, error } = result;
    if (status === 200) {
      setData(...data?.cms);
      setBaseUrl(data?.base_url + "uploads/");
    } else {
      console.log(error);
    }
  };

  return (
    <div className="banner banner-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={baseUrl + data?.img} className="banner-img" />
          </div>
          <div className="col-lg-6">
            <div className="text-col">
              <h2 className="banner-title">{data?.title}</h2>
              <p className="lead">{data?.sub_title}</p>
              <p>{data?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;