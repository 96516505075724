import React, { useEffect, useState } from "react";
import "./Header.css";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { selectContactDetails } from "../../Store/Store";

const Header = () => {
  const contactDetails = useSelector(selectContactDetails);
  const logo = contactDetails?.values?.settings?.logo;
  const baseUrl = contactDetails?.values?.base_url;

  // header stuck
  const [isSticky, setIsSticky] = useState(false);

  // const closeMenu = () => {
  //   if (window.innerWidth <= 991) {
  //     let menu = document.querySelector(".offcanvas");
  //     let menuBg = document.querySelector(".offcanvas-backdrop");
  //     menu.classList.remove("show");
  //     menu.classList.add("hiding");
  //     // menuBg.classList.remove('show')
  //     menuBg.remove();
  //     document.body.style.overflow = "";
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 70) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? "stickyHeader" : ""}`}>
      <div className="header-top"></div>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand logo" to="/">
            <img src={logo ? baseUrl + logo : "/assets/logo.png"} alt="logo" />
          </Link>
          <IconButton
            className="menu-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvasLg"
            aria-controls="navbarOffcanvasLg"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </IconButton>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="navbarOffcanvasLg"
            aria-labelledby="navbarOffcanvasLgLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                Menu
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav flex-grow-1 pe-3 align-items-center">
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to="/about-us">
                    About
                  </Link>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <HashLink className="nav-link" to="/#how-it-works">
                    How It Works
                  </HashLink>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <HashLink className="nav-link" to="/#faqs">
                    FAQ's
                  </HashLink>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>

              <div className="button-group">
                <a href='https://vendor.thegallery-llc.com/register' target="_blank">
                <Button variant="contained" className="main-button">
                  Become a Vendor
                </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;