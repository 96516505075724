import { useEffect, useState } from "react";

// Scroll To Top
export const scrollTop = () => {
    window.scrollTo(0, 0);
};
  
// Ant-d Form Password Validator
export const validatePassword = (form) => (_, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject("Confirm passwords do not match");
    }
    return Promise.resolve();
};

// Get Screen Width
export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window?.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return screenWidth;
};

// Ant-d Form Password Validator
export const validateCheckbox = (form) => (_, value) => {
  if (value) {
    return Promise.resolve();
  }
  return Promise.reject("You must agree to the terms and conditions");
};


// Ant-d Form Validate Phone Number
export const validatePhone =
  (form, phoneNum, countryCode, setPhoneValidation) => (_, value) => {
    const formatted_phone = phoneNum?.substring(countryCode?.length);
    if (!formatted_phone.length) {
      setPhoneValidation(true);
      return Promise.reject("You cannot leave this field blank");
    } else if (
      formatted_phone &&
      formatted_phone.length > 1 &&
      formatted_phone.length < 8
    ) {
      setPhoneValidation(true);
      return Promise.reject("Phone number is not valid");
    }
    setPhoneValidation(false);
    return Promise.resolve();
  };


// OnChange Phone Number
export const onChangePhone = (
  form,
  value,
  country,
  setCountryCode,
  setPhoneNum,
  setSlug
) => {
  setCountryCode(country.dialCode);
  setSlug(country.countryCode)
  setPhoneNum(value);
  form.validateFields(["phone"]);
};


// Langugae Switch for (Responses which are Rejected)
export const errMess = (result) => {
  // const { i18n } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || {};
  if(language === "en"){
    return result?.error?.en
  }
  else{
    return result?.error?.fr
  }
};


// Langugae Switch for (Responses which are  Successful)
export const succMess = (result) => {
  // const { i18n } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || {};
  if(language === "en"){
    return result?.data?.message?.en
  }
  else{
    return result?.data?.message?.fr
  }
};

// Clear Local Storage
export const clearLocalStorage = (navigate) => {
  localStorage.removeItem('userData');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token');
  localStorage.removeItem('persist:root');
  localStorage.removeItem("__paypal_storage__");
  if(navigate) {
    setTimeout(() => {
      navigate("/login")
    }, 1000);
  }
}